import { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Input from 'components/common/Input';
import {
  ArrowRight,
  DiscussionIcon,
  MailIcon,
  PhoneIcon,
  WorldIcon,
} from 'public/assets/icons';
import CreateEventSwitcherSettings from 'containers/CreateEventSwitcherSettings';
import AttendanceManagementDrawer from 'containers/AttendanceManagementDrawer';
import { composeValidators, required } from 'utils/validators';
import EventOwnerShipDrawer from 'containers/EventOwnerShipDrawer';
import { IStepTwo } from './ICreateEventPostForm';
import EventBrands from './EventBrands';
import MyCollaborators from './MyCollaborators';
import Routes from './Routes';

import {
  AttendanceBlock,
  AttendanceBlockLeft,
  AttendanceBlockRight,
  AttendanceBlockText,
  AttendanceWrapper,
  AttendanceWrapperLeft,
  InputsContainers,
  SectionWrapper,
  SelectorWrapper,
  SeparateSettingsHeader,
  EventOwnerWrapper,
  EventOwnerBlock,
  EventSectionWrapper,
} from './CreateEventPostForm.styles';
import RegistrationFormDrawer from 'containers/RegistrationFormDrawer';

const StepTwo = ({ form, initialValues }: IStepTwo) => {
  const { t } = useTranslation();

  const [isAttendanceDrawerOpen, setIsAttendanceDrawerOpen] = useState(false);
  const [isRegisterFormDrawerOpen, setIsRegisterFormDrawerOpen] =
    useState(false);

  const [attendanceManagement, setAttendanceManagement] = useState({
    title: null,
    text: null,
    attenderManagement: null,
  });

  return (
    <>
      <SectionWrapper pt={20} pb={17.5}>
        <SelectorWrapper>
          <EventBrands />
          <MyCollaborators />
        </SelectorWrapper>

        <InputsContainers>
          <Input
            id="contactPhone"
            name="contactPhone"
            type="text"
            label={t('event:contactPhone')}
            prefix={<PhoneIcon />}
          />
          <Input
            id="contactEmail"
            name="contactEmail"
            type="text"
            label={t('event:contactEmail')}
            prefix={<MailIcon />}
          />
          <Input
            id="contactWebsite"
            name="contactWebsite"
            type="text"
            label={t('event:eventWebpageOrRouteLink')}
            prefix={<WorldIcon />}
          />
          <Input
            id="attendeeCap"
            name="attendeeCap"
            type="text"
            label={t('event:attendeeCap')}
            prefix={<DiscussionIcon height="18" width="18" color="#8D8D8D" />}
          />
        </InputsContainers>

        <SelectorWrapper>
          <Routes />
        </SelectorWrapper>
      </SectionWrapper>

      <SectionWrapper>
        <Field name="eventSettings">
          {({ input }) => {
            return (
              <CreateEventSwitcherSettings
                eventSettings={input?.value}
                setEventSettings={input?.onChange}
                initialValues={initialValues}
                form={form}
              />
            );
          }}
        </Field>
      </SectionWrapper>

      <SectionWrapper>
        <Field name="attenderManagement">
          {() => {
            if (attendanceManagement?.attenderManagement) {
              form.change(
                'attenderManagement',
                attendanceManagement?.attenderManagement
              );
            }
            return (
              <AttendanceWrapper
                onClick={() => setIsAttendanceDrawerOpen(true)}
              >
                <AttendanceWrapperLeft>
                  <SeparateSettingsHeader type="h3" mb={8}>
                    {t('event:attendanceManagement:heading')}
                  </SeparateSettingsHeader>
                  <AttendanceBlockRight>
                    <ArrowRight />
                  </AttendanceBlockRight>
                </AttendanceWrapperLeft>
                {attendanceManagement?.text && (
                  <AttendanceBlock>
                    <AttendanceBlockLeft>
                      <SeparateSettingsHeader type="h4" fs={15} fw={500}>
                        {attendanceManagement?.title &&
                          attendanceManagement?.title}
                      </SeparateSettingsHeader>
                      <AttendanceBlockText>
                        {attendanceManagement?.text &&
                          attendanceManagement?.text}
                      </AttendanceBlockText>
                    </AttendanceBlockLeft>
                  </AttendanceBlock>
                )}
              </AttendanceWrapper>
            );
          }}
        </Field>
      </SectionWrapper>

      <SectionWrapper>
        <Field name="registrationForm">
          {() => (
            <AttendanceWrapper
              onClick={() => setIsRegisterFormDrawerOpen(true)}
            >
              <AttendanceWrapperLeft>
                <SeparateSettingsHeader type="h3" mb={8}>
                  {t('event:registrationManagement')}
                </SeparateSettingsHeader>
                <AttendanceBlockRight>
                  <ArrowRight />
                </AttendanceBlockRight>
              </AttendanceWrapperLeft>

              <AttendanceBlock>
                <AttendanceBlockLeft>
                  <SeparateSettingsHeader type="h4" fs={15} fw={500}>
                    {t('event:registrationForm')}
                  </SeparateSettingsHeader>
                  <AttendanceBlockText>
                    {
                      t('event:registrationFormDescription')
                    }
                  </AttendanceBlockText>
                </AttendanceBlockLeft>
              </AttendanceBlock>
            </AttendanceWrapper>
          )}
        </Field>
      </SectionWrapper>

      <AttendanceManagementDrawer
        open={isAttendanceDrawerOpen}
        setOpen={setIsAttendanceDrawerOpen}
        setAttendanceManagement={setAttendanceManagement}
      />

      <RegistrationFormDrawer
        open={isRegisterFormDrawerOpen}
        setOpen={setIsRegisterFormDrawerOpen}
        form={form}
        initialValues={initialValues}
      />
    </>
  );
};

export default StepTwo;
