import React, { useState } from 'react';
import Button from 'components/common/Button';
import {
  ContentWrap,
  ModalWrap,
  HeaderText,
  CloseIconWrap,
  LabelCheckboxWrap,
  FormFieldWrap,
  LabelText,
  LabelOptionWrap,
  LabelText2,
  ButtonWrapper,
} from './FieldDetailsModal.style';
import { IFieldDetailsModal } from './IFieldDetailsModal';
import Input from 'components/common/Input';
import { CrossIcon, PlusIcon } from 'public/assets/icons';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { composeValidators, required } from 'utils/validators';
import Form from 'components/common/Form';
import CheckBox from 'components/common/CheckBox';
import {
  IFormField,
  FormFieldType,
} from 'components/DynamicFormComponents/RegistrationForm/FormField/FormField';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

export const FieldDetailsModal = ({
  open,
  onClose,
  onSubmit,
  fieldType,
  initialValues,
}: IFieldDetailsModal) => {
  const { t } = useTranslation();
  const handleSubmit = (field: IFormField) => {
    onSubmit({ ...field, type: fieldType });
    onClose();
  };

  return (
    open && (
      <ContentWrap onClick={onClose}>
        <ModalWrap onClick={(e) => e.stopPropagation()}>
          <HeaderText>{t('event:fieldDetails')}</HeaderText>

          <FinalForm
            mutators={{ ...arrayMutators }}
            onSubmit={(e: IFormField) => handleSubmit(e)}
            initialValues={initialValues}
            render={(formRenderProps: FormRenderProps) => {
              const { handleSubmit } = formRenderProps;

              return (
                <Form onSubmit={handleSubmit}>
                  <FormFieldWrap>
                    <Input
                      id="field-name"
                      name="label"
                      type="text"
                      label="Field Name"
                      validate={composeValidators(
                        required('This field is required!')
                      )}
                    />

                    <Field name="required" type="checkbox">
                      {(props) => (
                        <LabelCheckboxWrap>
                          <CheckBox
                            {...props.input}
                            type="checkbox"
                            checked={props.input.checked}
                            height={10}
                            width={10}
                          />
                          <LabelText>Required</LabelText>
                        </LabelCheckboxWrap>
                      )}
                    </Field>

                    {fieldType === FormFieldType.MultipleOptions && (
                      <>
                        <LabelOptionWrap>
                          <LabelText2>{t('event:addOption')}</LabelText2>
                          <PlusIcon
                            onClick={() =>
                              formRenderProps.form.mutators.push(
                                'options',
                                undefined
                              )
                            }
                          />
                        </LabelOptionWrap>

                        <FieldArray name="options">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div
                                key={name}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Input
                                  id={`option-name-${index}`}
                                  name={`${name}.label`}
                                  type="text"
                                  label="Option Label"
                                  validate={composeValidators(
                                    required('This field is required!')
                                  )}
                                />
                                <span
                                  onClick={() => fields.remove(index)}
                                  style={{ cursor: 'pointer', marginTop: 15 }}
                                >
                                  <CrossIcon color="#8D8D8D" />
                                </span>
                              </div>
                            ))
                          }
                        </FieldArray>
                      </>
                    )}
                  </FormFieldWrap>
                  <ButtonWrapper>
                    <Button
                      variant="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                    >
                      {t('settings:submitBtn')}
                    </Button>
                  </ButtonWrapper>
                </Form>
              );
            }}
          />

          <CloseIconWrap onClick={onClose}>
            <CrossIcon color="#8D8D8D" />
          </CloseIconWrap>
        </ModalWrap>
      </ContentWrap>
    )
  );
};
