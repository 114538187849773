export const actionTypes = {
  OPEN_SIDEBAR_SAVED_ROUTES: 'OPEN_SIDEBAR_SAVED_ROUTES',
  CLOSE_SIDEBAR_SAVED_ROUTES: 'CLOSE_SIDEBAR_SAVED_ROUTES',
  OPEN_SIDEBAR_WHERE_TO: 'OPEN_SIDEBAR_WHERE_TO',
  CLOSE_SIDEBAR_WHERE_TO: 'CLOSE_SIDEBAR_WHERE_TO',
  OPEN_SIDEBAR_PLAN_ROUTE: 'OPEN_SIDEBAR_PLAN_ROUTE',
  CLOSE_SIDEBAR_PLAN_ROUTE: 'CLOSE_SIDEBAR_PLAN_ROUTE',
  OPEN_SIDEBAR_CHOSE_START_POINT: 'OPEN_SIDEBAR_CHOSE_START_POINT',
  CLOSE_SIDEBAR_CHOSE_START_POINT: 'CLOSE_SIDEBAR_CHOSE_START_POINT',
  OPEN_SIDEBAR_CHOSE_END_POINT: 'OPEN_SIDEBAR_CHOSE_END_POINT',
  CLOSE_SIDEBAR_CHOSE_END_POINT: 'CLOSE_SIDEBAR_CHOSE_END_POINT',
  OPEN_SIDEBAR_CHOSE_WAY_POINT: 'OPEN_SIDEBAR_CHOSE_WAY_POINT',
  CLOSE_SIDEBAR_CHOSE_WAY_POINT: 'CLOSE_SIDEBAR_CHOSE_WAY_POINT',
  OPEN_SIDEBAR_ROUTE_DETAIL: 'OPEN_SIDEBAR_ROUTE_DETAIL',
  CLOSE_SIDEBAR_ROUTE_DETAIL: 'CLOSE_SIDEBAR_ROUTE_DETAIL',
  CLEAR_MAP_SIDEBAR_STORE: 'CLEAR_MAP_SIDEBAR_STORE',

  CREATE_ROUTE_START: 'CREATE_ROUTE_START',
  CREATE_ROUTE_SUCCESS: 'CREATE_ROUTE_SUCCESS',
  CREATE_ROUTE_ERROR: 'CREATE_ROUTE_ERROR',
  UPDATE_ROUTE_START: 'UPDATE_ROUTE_START',
  UPDATE_ROUTE_SUCCESS: 'UPDATE_ROUTE_SUCCESS',
  UPDATE_ROUTE_ERROR: 'UPDATE_ROUTE_ERROR',
  DELETE_ROUTE_START: 'DELETE_ROUTE_START',
  DELETE_ROUTE_SUCCESS: 'DELETE_ROUTE_SUCCESS',
  DELETE_ROUTE_ERROR: 'DELETE_ROUTE_ERROR',
  CREATE_ROUTE_RATING_START: 'CREATE_ROUTE_RATING_START',
  CREATE_ROUTE_RATING_SUCCESS: 'CREATE_ROUTE_RATING_SUCCESS',
  CREATE_ROUTE_RATING_ERROR: 'CREATE_ROUTE_RATING_ERROR',
  UPDATE_ROUTE_RATING_START: 'UPDATE_ROUTE_RATING_START',
  UPDATE_ROUTE_RATING_SUCCESS: 'UPDATE_ROUTE_RATING_SUCCESS',
  UPDATE_ROUTE_RATING_ERROR: 'UPDATE_ROUTE_RATING_ERROR',
  DELETE_ROUTE_RATING_START: 'DELETE_ROUTE_RATING_START',
  DELETE_ROUTE_RATING_SUCCESS: 'DELETE_ROUTE_RATING_SUCCESS',
  DELETE_ROUTE_RATING_ERROR: 'DELETE_ROUTE_RATING_ERROR',
  GET_ROUTE_DETAILS_BY_ID_START: 'GET_ROUTE_DETAILS_BY_ID_START',
  GET_ROUTE_DETAILS_BY_ID_SUCCESS: 'GET_ROUTE_DETAILS_BY_ID_SUCCESS',
  GET_ROUTE_DETAILS_BY_ID_ERROR: 'GET_ROUTE_DETAILS_BY_ID_ERROR',
  ADD_ROUTE_TO_FAVORITES_START: 'ADD_ROUTE_TO_FAVORITES_START',
  ADD_ROUTE_TO_FAVORITES_SUCCESS: 'ADD_ROUTE_TO_FAVORITES_SUCCESS',
  ADD_ROUTE_TO_FAVORITES_ERROR: 'ADD_ROUTE_TO_FAVORITES_ERROR',
  DELETE_ROUTE_FROM_FAVORITES_START: 'DELETE_ROUTE_FROM_FAVORITES_START',
  DELETE_ROUTE_FROM_FAVORITES_SUCCESS: 'DELETE_ROUTE_FROM_FAVORITES_SUCCESS',
  DELETE_ROUTE_FROM_FAVORITES_ERROR: 'DELETE_ROUTE_FROM_FAVORITES_ERROR',
  GET_SAVED_ROUTES_START: 'GET_SAVED_ROUTES_START',
  GET_SAVED_ROUTES_SUCCESS: 'GET_SAVED_ROUTES_SUCCESS',
  GET_SAVED_ROUTES_ERROR: 'GET_SAVED_ROUTES_ERROR',

  GET_DIRECTIONS_FOR_DRIVING_START: 'GET_DIRECTIONS_FOR_DRIVING_START',
  GET_DIRECTIONS_FOR_DRIVING_SUCCESS: 'GET_DIRECTIONS_FOR_DRIVING_SUCCESS',
  GET_DIRECTIONS_FOR_DRIVING_ERROR: 'GET_DIRECTIONS_FOR_DRIVING_ERROR',

  SET_SELECTED_WAY_POINT_ID: 'SET_SELECTED_WAY_POINT_ID',
  SET_ROUTE_FOR_EDIT_DATA: 'SET_ROUTE_FOR_EDIT_DATA',
  SET_ROUTE_DETAILS_ID: 'SET_ROUTE_DETAILS_ID',
  CLEAR_APP_CLUB_STATE: 'CLEAR_APP_CLUB_STATE',
  SET_MAP_FILTER: 'SET_MAP_FILTER',
};

interface SavedRouteData {
  routeId: string;
  routeName: string;
  waypoints?: any;
}

export const clearAppClubState = () => ({
  type: actionTypes.CLEAR_APP_CLUB_STATE,
});
// SAVED ROUTES SIDEBAR ACTIONS
export const openSidebarSavedRoutes = () => ({
  type: actionTypes.OPEN_SIDEBAR_SAVED_ROUTES,
});

export const closeSidebarSavedRoutes = () => ({
  type: actionTypes.CLOSE_SIDEBAR_SAVED_ROUTES,
});

// WHERE TO SIDEBAR ACTIONS
export const openSidebarWhereTo = () => ({
  type: actionTypes.OPEN_SIDEBAR_WHERE_TO,
});

export const closeSidebarWhereTo = () => ({
  type: actionTypes.CLOSE_SIDEBAR_WHERE_TO,
});

// PLANE ROUTE SIDEBAR ACTIONS
export const openSidebarPlanRoute = (data?: SavedRouteData) => ({
  type: actionTypes.OPEN_SIDEBAR_PLAN_ROUTE,
  data,
});

export const closeSidebarPlanRoute = () => ({
  type: actionTypes.CLOSE_SIDEBAR_PLAN_ROUTE,
});

// CHOSE START POINT SIDEBAR ACTIONS
export const openSidebarChoseStartPoint = () => ({
  type: actionTypes.OPEN_SIDEBAR_CHOSE_START_POINT,
});

export const closeSidebarChoseStartPoint = () => ({
  type: actionTypes.CLOSE_SIDEBAR_CHOSE_START_POINT,
});

// CHOSE END POINT SIDEBAR ACTIONS
export const openSidebarChoseEndPoint = () => ({
  type: actionTypes.OPEN_SIDEBAR_CHOSE_END_POINT,
});

export const closeSidebarChoseEndPoint = () => ({
  type: actionTypes.CLOSE_SIDEBAR_CHOSE_END_POINT,
});

// CHOSE WAY POINT SIDEBAR ACTIONS
export const openSidebarChoseWayPoint = () => ({
  type: actionTypes.OPEN_SIDEBAR_CHOSE_WAY_POINT,
});

export const closeSidebarChoseWayPoint = () => ({
  type: actionTypes.CLOSE_SIDEBAR_CHOSE_WAY_POINT,
});

// ROUTE DETAILS SIDEBAR ACTIONS
export const openSidebarRouteDetail = (data) => ({
  type: actionTypes.OPEN_SIDEBAR_ROUTE_DETAIL,
  data,
});

export const closeSidebarRouteDetail = () => ({
  type: actionTypes.CLOSE_SIDEBAR_ROUTE_DETAIL,
});

// CLEAR MAP SIDEBAR ACTIONS
export const clearMapSidebarStore = () => ({
  type: actionTypes.CLEAR_MAP_SIDEBAR_STORE,
});

// CREATE ROUTE ACTIONS
export const createRouteStart = (data) => ({
  type: actionTypes.CREATE_ROUTE_START,
  data,
});

export const createRouteSuccess = () => ({
  type: actionTypes.CREATE_ROUTE_SUCCESS,
});

export const createRouteError = (error) => ({
  type: actionTypes.CREATE_ROUTE_ERROR,
  error,
});

// UPDATE ROUTE ACTIONS
export const updateRouteStart = (data) => ({
  type: actionTypes.UPDATE_ROUTE_START,
  data,
});

export const updateRouteSuccess = () => ({
  type: actionTypes.UPDATE_ROUTE_SUCCESS,
});

export const updateRouteError = (error) => ({
  type: actionTypes.UPDATE_ROUTE_ERROR,
  error,
});

// DELETE ROUTE ACTIONS
export const deleteRouteStart = (data) => ({
  type: actionTypes.DELETE_ROUTE_START,
  data,
});

export const deleteRouteSuccess = () => ({
  type: actionTypes.DELETE_ROUTE_SUCCESS,
});

export const deleteRouteError = (error) => ({
  type: actionTypes.DELETE_ROUTE_ERROR,
  error,
});

// GET ROUTE DETAILS ACTIONS
export const getRouteDetailsByIdStart = (data) => ({
  type: actionTypes.GET_ROUTE_DETAILS_BY_ID_START,
  data,
});

export const getRouteDetailsByIdSuccess = (data) => ({
  type: actionTypes.GET_ROUTE_DETAILS_BY_ID_SUCCESS,
  data,
});

export const getRouteDetailsByIdError = (error) => ({
  type: actionTypes.GET_ROUTE_DETAILS_BY_ID_ERROR,
  error,
});

// CREATE ROUTE RATING ACTIONS
export const createRouteRatingStart = (data) => ({
  type: actionTypes.CREATE_ROUTE_RATING_START,
  data,
});

export const createRouteRatingSuccess = (data) => ({
  type: actionTypes.CREATE_ROUTE_RATING_SUCCESS,
  data,
});

export const createRouteRatingError = (error) => ({
  type: actionTypes.CREATE_ROUTE_RATING_ERROR,
  error,
});

// DELETE ROUTE RATING ACTIONS
export const updateRouteRatingStart = (data) => ({
  type: actionTypes.UPDATE_ROUTE_RATING_START,
  data,
});

export const updateRouteRatingSuccess = (data) => ({
  type: actionTypes.UPDATE_ROUTE_RATING_SUCCESS,
  data,
});

export const updateRouteRatingError = (error) => ({
  type: actionTypes.UPDATE_ROUTE_RATING_ERROR,
  error,
});

// DELETE ROUTE RATING ACTIONS
export const deleteRouteRatingStart = (data) => ({
  type: actionTypes.DELETE_ROUTE_RATING_START,
  data,
});

export const deleteRouteRatingSuccess = (data) => ({
  type: actionTypes.DELETE_ROUTE_RATING_START,
  data,
});

export const deleteRouteRatingError = (error) => ({
  type: actionTypes.DELETE_ROUTE_RATING_ERROR,
  error,
});

// ADD ROUTE TO FAVORITES ACTIONS
export const addRouteToFavoritesStart = (data) => ({
  type: actionTypes.ADD_ROUTE_TO_FAVORITES_START,
  data,
});

export const addRouteToFavoritesSuccess = () => ({
  type: actionTypes.ADD_ROUTE_TO_FAVORITES_SUCCESS,
});

export const addRouteToFavoritesError = (error) => ({
  type: actionTypes.ADD_ROUTE_TO_FAVORITES_ERROR,
  error,
});

// DELETE ROUTE FROM FAVORITES ACTIONS
export const deleteRouteFromFavoritesStart = (data) => ({
  type: actionTypes.DELETE_ROUTE_FROM_FAVORITES_START,
  data,
});

export const deleteRouteFromFavoritesSuccess = () => ({
  type: actionTypes.DELETE_ROUTE_FROM_FAVORITES_SUCCESS,
});

export const deleteRouteFromFavoritesError = (error) => ({
  type: actionTypes.DELETE_ROUTE_FROM_FAVORITES_ERROR,
  error,
});

// GET SAVED ROUTES ACTIONS
export const getSavedRoutesStart = (data) => ({
  type: actionTypes.GET_SAVED_ROUTES_START,
  data,
});

export const getSavedRoutesSuccess = (data) => ({
  type: actionTypes.GET_SAVED_ROUTES_SUCCESS,
  data,
});

export const getSavedRoutesError = (error) => ({
  type: actionTypes.GET_SAVED_ROUTES_ERROR,
  error,
});

// GET DIRECTIONS FOR DRIVING ACTIONS
export const getDirectionsForDrivingStart = (data) => ({
  type: actionTypes.GET_DIRECTIONS_FOR_DRIVING_START,
  data,
});

export const getDirectionsForDrivingSuccess = (data) => ({
  type: actionTypes.GET_DIRECTIONS_FOR_DRIVING_SUCCESS,
  data,
});

export const getDirectionsForDrivingError = (data) => ({
  type: actionTypes.GET_DIRECTIONS_FOR_DRIVING_ERROR,
  data,
});

// SET SELECTED WAY POINT ID ACTIONS
export const setSelectedWayPointId = (data) => ({
  type: actionTypes.SET_SELECTED_WAY_POINT_ID,
  data,
});

// SET ROUTE FOR EDIT DATA ACTIONS
export const setRouteForEditData = (data) => ({
  type: actionTypes.SET_ROUTE_FOR_EDIT_DATA,
  data,
});

// SET ROUTE ID
export const setRouteDetailsId = (data) => ({
  type: actionTypes.SET_ROUTE_DETAILS_ID,
  data,
});

export const setMapFilter = (data) => ({
  type: actionTypes.SET_MAP_FILTER,
  data,
});
