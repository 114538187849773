import React from 'react';
import { format } from 'date-fns';
import {
  SectionHeader,
  SectionWrapper,
  Text,
} from './RegistrationFormDrawer.styles';

import moment, { Moment } from 'moment';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';
import {
  FieldCollection,
  IFieldCollection,
} from 'components/DynamicFormComponents/RegistrationForm/FieldCollection/FieldCollection';

interface RegistrationFormPreviewProps {
  description: string;
  startDate: Moment;
  endDate: Moment;
  timeZone: string;
  fieldCollections: IFieldCollection[];
}

const RegistrationFormPreview: React.FC<RegistrationFormPreviewProps> = ({
  description,
  startDate,
  endDate,
  timeZone,
  fieldCollections,
}) => {
  const getTimezone = () => {
    const minimalTimeOne = minimalTimezoneSet.find(
      (d) => d?.offset === timeZone
    );
    if (!minimalTimeOne) return '';

    const { offset, tzCode, label } = minimalTimeOne;
    const tzAbbrMoment = moment.tz(tzCode).format('z');
    const isAbbrValid = !tzAbbrMoment.match(/[+-]/);

    return isAbbrValid ? `(GMT${offset}) ${tzAbbrMoment}` : label;
  };

  return (
    <div>
      <SectionWrapper>
        <SectionHeader>Description</SectionHeader>
        <Text>{description}</Text>
      </SectionWrapper>

      <SectionWrapper>
        <SectionHeader>Registration Dates</SectionHeader>
        <Text>Opens: {format(startDate.toDate(), 'MMM d, yyyy | h:mm a')}</Text>
        <Text>Closes: {format(endDate.toDate(), 'MMM d, yyyy | h:mm a')}</Text>
        <Text>Timezone: {getTimezone()}</Text>
      </SectionWrapper>

      <SectionWrapper>
        <Text>Questions</Text>
        {fieldCollections.map((collection, index) => (
          <FieldCollection
            key={`${collection.name}-${index}`}
            collection={collection}
            readOnly
          />
        ))}
      </SectionWrapper>
    </div>
  );
};

export default RegistrationFormPreview;
