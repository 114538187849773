/* eslint-disable no-unused-expressions */
import { call, put, takeEvery } from 'redux-saga/effects';
import Router from 'next/router';

import { routesPath } from 'utils/constants/routePath';
import {
  createRoute,
  updateRoute,
  deleteRoute,
  createRouteRating,
  updateRouteRating,
  deleteRouteRating,
  getRouteDetailsById,
  addRouteToFavorites,
  deleteRouteFromFavorites,
  getSavedRoutes,
  getDirectionsForDriving,
} from 'services';
import {
  actionTypes,
  createRouteSuccess,
  createRouteError,
  updateRouteSuccess,
  updateRouteError,
  deleteRouteSuccess,
  deleteRouteError,
  createRouteRatingSuccess,
  createRouteRatingError,
  updateRouteRatingSuccess,
  updateRouteRatingError,
  deleteRouteRatingSuccess,
  deleteRouteRatingError,
  getRouteDetailsByIdSuccess,
  getRouteDetailsByIdError,
  getRouteDetailsByIdStart,
  addRouteToFavoritesSuccess,
  addRouteToFavoritesError,
  deleteRouteFromFavoritesSuccess,
  deleteRouteFromFavoritesError,
  getSavedRoutesSuccess,
  getSavedRoutesStart,
  getSavedRoutesError,
  getDirectionsForDrivingSuccess,
  getDirectionsForDrivingError,
  setRouteForEditData,
  closeSidebarRouteDetail,
  openSidebarRouteDetail,
  closeSidebarWhereTo,
  closeSidebarPlanRoute,
} from './actions';

function* workerCreateRoute(action) {
  try {
    const waypoints = action.data.routeData.map((el) => ({
      location: {
        coordinates: [el.lng, el.lat],
      },
      placeId: el.placeId,
      waypointName: el.waypointName,
    }));

    const params = {
      routeData: {
        name: action.data.routeName,
        waypoints,
        isPublic: action.data.isPublic,
      },
    };

    const resp = yield call(createRoute, params);

    if (resp.data?._id) {
      yield put(createRouteSuccess());
      yield put(closeSidebarWhereTo());
      yield put(openSidebarRouteDetail(resp.data._id));
      yield put(closeSidebarPlanRoute());
    } else {
      yield put(createRouteError(resp.data));
    }
  } catch (err) {
    yield put(createRouteError(err));
  }
}

function* workerUpdateRoute(action) {
  try {
    const { routeData, routeName, routeId } = action.data;

    const waypoints = routeData.map((el) => ({
      location: {
        coordinates: [el.lng, el.lat],
      },
      placeId: el.placeId,
      waypointName: el.waypointName,
    }));

    const params = {
      routeData: {
        name: routeName,
        waypoints,
      },
      routeId,
    };

    const resp = yield call(updateRoute, params);

    console.log('update route resp', resp);

    if (resp.data?._id) {
      yield put(updateRouteSuccess());
      yield put(closeSidebarPlanRoute());
    } else {
      yield put(updateRouteError(resp.data));
    }

    resp.code === 200 || resp.status === 204
      ? yield put(updateRouteSuccess())
      : put(updateRouteError(resp.data));
  } catch (err) {
    yield put(updateRouteError(err));
  }
}

function* workerDeleteRoute(action) {
  try {
    const resp = yield call(deleteRoute, action.data);

    if (resp.data && !resp.data?.error) {
      yield put(deleteRouteSuccess());
      yield put(setRouteForEditData(null));
      yield put(closeSidebarRouteDetail());

      if (action.data.isRouteDetailsScreen) {
        Router.push(routesPath.map);
      }
    } else {
      put(deleteRouteError(resp.data));
    }
  } catch (err) {
    yield put(deleteRouteError(err));
  }
}

function* workerGetRouteDetailsById(action) {
  try {
    console.log();

    const resp = yield call(getRouteDetailsById, action.data);

    resp.data?._id
      ? yield put(getRouteDetailsByIdSuccess(resp.data))
      : yield put(getRouteDetailsByIdError(resp.data));
  } catch (err) {
    yield put(getRouteDetailsByIdError(err));
  }
}

function* workerCreateRouteRating(action) {
  const { rating, routeDetailsId } = action.data;
  try {
    const params = {
      routeData: {
        rating,
      },
      routeId: routeDetailsId,
    };
    const resp = yield call(createRouteRating, params);

    if (resp.data?._id) {
      yield put(createRouteRatingSuccess(resp.data._id));
    } else {
      yield put(createRouteRatingError(resp.data));
    }
  } catch (err) {
    yield put(createRouteRatingError(err));
  }
}

function* workerUpdateRouteRating(action) {
  const { rating, routeDetailsId } = action.data;
  try {
    const params = {
      routeData: {
        rating,
      },
      routeId: routeDetailsId,
    };
    const resp = yield call(updateRouteRating, params);

    if (resp.data?._id) {
      yield put(updateRouteRatingSuccess(resp.data._id));
    } else {
      yield put(updateRouteRatingError(resp.data));
    }
  } catch (err) {
    yield put(updateRouteRatingError(err));
  }
}

function* workerDeleteRouteRating(action) {
  const { routeDetailsId } = action.data;
  try {
    const params = {
      routeId: routeDetailsId,
    };
    const resp = yield call(deleteRouteRating, params);

    if (resp.data?._id) {
      yield put(deleteRouteRatingSuccess(resp.data._id));
    } else {
      yield put(deleteRouteRatingError(resp.data));
    }
  } catch (err) {
    yield put(deleteRouteRatingError(err));
  }
}

function* workerAddRouteToFavorites(action) {
  try {
    const resp = yield call(addRouteToFavorites, action.data);

    if (resp.data && !resp.data?.error) {
      yield put(addRouteToFavoritesSuccess());
      yield put(getRouteDetailsByIdStart(action.data));
    } else {
      yield put(addRouteToFavoritesError(resp.data));
    }
  } catch (err) {
    yield put(addRouteToFavoritesError(err));
  }
}

function* workerDeleteRouteFromFavorites(action) {
  try {
    const resp = yield call(deleteRouteFromFavorites, action.data);

    console.log('delete from f', resp);

    if (resp.data && !resp.data?.error) {
      yield put(deleteRouteFromFavoritesSuccess());
      yield put(getRouteDetailsByIdStart(action.data));
      yield put(getSavedRoutesStart({ offset: 0, limit: 20 }));
    } else {
      yield put(deleteRouteFromFavoritesError(resp.data));
    }
  } catch (err) {
    yield put(deleteRouteFromFavoritesError(err));
  }
}

function* workerGetSavedRoutes(action) {
  try {
    const resp = yield call(getSavedRoutes, action.data);

    resp.data
      ? yield put(
          getSavedRoutesSuccess({ data: resp.data, requestInfo: action.data })
        )
      : yield put(getSavedRoutesError(resp.data));
  } catch (err) {
    yield put(getSavedRoutesError(err));
  }
}

function* workerGetDirectionsForDriving(action) {
  try {
    const resp = yield call(getDirectionsForDriving, action.data);

    resp.code === 'Ok'
      ? yield put(getDirectionsForDrivingSuccess(resp.routes[0]))
      : yield put(getDirectionsForDrivingError(resp.data));
  } catch (err) {
    yield put(getDirectionsForDrivingError(err));
  }
}

function* watchMap() {
  yield takeEvery(actionTypes.CREATE_ROUTE_START, workerCreateRoute);
  yield takeEvery(actionTypes.UPDATE_ROUTE_START, workerUpdateRoute);
  yield takeEvery(actionTypes.DELETE_ROUTE_START, workerDeleteRoute);
  yield takeEvery(
    actionTypes.GET_ROUTE_DETAILS_BY_ID_START,
    workerGetRouteDetailsById
  );
  yield takeEvery(
    actionTypes.CREATE_ROUTE_RATING_START,
    workerCreateRouteRating
  );
  yield takeEvery(
    actionTypes.UPDATE_ROUTE_RATING_START,
    workerUpdateRouteRating
  );
  yield takeEvery(
    actionTypes.DELETE_ROUTE_RATING_START,
    workerDeleteRouteRating
  );
  yield takeEvery(
    actionTypes.ADD_ROUTE_TO_FAVORITES_START,
    workerAddRouteToFavorites
  );
  yield takeEvery(
    actionTypes.DELETE_ROUTE_FROM_FAVORITES_START,
    workerDeleteRouteFromFavorites
  );
  yield takeEvery(actionTypes.GET_SAVED_ROUTES_START, workerGetSavedRoutes);
  yield takeEvery(
    actionTypes.GET_DIRECTIONS_FOR_DRIVING_START,
    workerGetDirectionsForDriving
  );
}

export default watchMap;
