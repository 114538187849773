import { FINISH_WAYPOINT_TYPE, START_WAYPOINT_TYPE } from './constants';

export const getWaypointsArray = (waypointArr: any[], newWaypoint: any) => {
  if (!waypointArr?.length) {
    return [{ ...newWaypoint, type: START_WAYPOINT_TYPE }];
  }
  if (waypointArr.length === 1) {
    if (
      waypointArr?.find((_waypoint) => _waypoint?.type === FINISH_WAYPOINT_TYPE)
    ) {
      return [{ ...newWaypoint, type: START_WAYPOINT_TYPE }].concat(
        waypointArr
      );
    }
    return waypointArr.concat({ ...newWaypoint, type: FINISH_WAYPOINT_TYPE });
  }
  return reAssignTypeToWaypoints([...waypointArr, newWaypoint]);
};
export const reAssignTypeToWaypoints = (waypointArr: any) =>
  waypointArr.map((_waypoint: any, index: number) => ({
    ..._waypoint,
    type:
      index === 0
        ? START_WAYPOINT_TYPE
        : index === waypointArr?.length - 1
        ? FINISH_WAYPOINT_TYPE
        : null,
  }));
