import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'components/Drawer';
import { IRegistrationFormDrawer } from './IRegistrationFormDrawer';
import {
  FieldCollection,
  IFieldCollection,
} from 'components/DynamicFormComponents/RegistrationForm/FieldCollection/FieldCollection';
import {
  getCollectionByEnum,
  PreDefinedCollection,
} from './PreDefinedFieldCollections';
import Text from 'components/common/Text';
import {
  SectionHeader,
  SectionWrapper,
  Select,
  SelectIconWrap,
  SelectWrapper,
  SeparateSettingsHeader,
  TimeZoneText,
} from './RegistrationFormDrawer.styles';
import { InputDescriptionField, Pill } from 'components';
import { Field } from 'react-final-form';
import ScheduleTime from 'components/ScheduleTime';
import { getDateInOriginalTimezone } from 'utils/date';
import moment, { Moment } from 'moment';
import { mapTimezoneOffsetToMinutes } from 'utils/time';
import { WorldIcon } from 'public/assets/icons';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';
import { format } from 'date-fns';
import momentTimezone from 'moment-timezone';
import { composeValidators, required } from 'utils/validators';
import { FieldDetailsModal } from 'components/Modals/FieldDetailsModal/FieldDetailsModal';
import RegistrationFormPreview from './RegistrationFormPreview';
import {
  FormFieldType,
  FormFieldTypeLabels,
  IFormField,
} from 'components/DynamicFormComponents/RegistrationForm/FormField/FormField';

interface RegistrationFormData {
  description: string;
  openingDate: number;
  closingDate: number;
  timeZone: string;
  fieldCollections: IFieldCollection[];
  type: 'registration';
}

const RegistrationFormDrawer: React.FC<IRegistrationFormDrawer> = ({
  open,
  setOpen,
  form,
  initialValues,
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [fieldType, setFieldType] = useState<FormFieldType | null>(null);
  const [fieldToEdit, setFieldToEdit] = useState<IFormField | null>(null);
  const [fieldCollections, setFieldCollections] = useState<IFieldCollection[]>(
    initialValues?.fieldCollections || []
  );

  const [registrationStart, setRegistrationStart] = useState<Moment>(
    initialValues?.startDate ? moment(initialValues.startDate) : moment()
  );

  const [registrationEnd, setRegistrationEnd] = useState<Moment>(
    initialValues?.endDate
      ? moment(initialValues.endDate)
      : moment().add(1, 'hour')
  );

  const [timeZone, setTimeZone] = useState<string>(
    initialValues?.timeZone
      ? momentTimezone.tz(initialValues.timeZone).format('Z')
      : format(new Date(), 'xxx')
  );
  const [previewData, setPreviewData] = useState<RegistrationFormData | null>(
    null
  );

  const handleAddCollection = (collectionEnum: PreDefinedCollection) => {
    const collection = getCollectionByEnum(collectionEnum);
    setFieldCollections((prev) => [...prev, collection]);
  };

  const handleAddCustomField = (type: FormFieldType) => {
    setFieldType(type);
    setIsModalOpen(true);
  };

  const handleRemoveField = (collectionIndex: number, fieldIndex: number) => {
    setFieldCollections((prevCollections) => {
      const newCollections = [...prevCollections];
      newCollections[collectionIndex].fields.splice(fieldIndex, 1);
      return newCollections;
    });
  };

  const handleEditField = (collectionIndex: number, fieldIndex: number) => {
    const field = fieldCollections[collectionIndex].fields[fieldIndex];
    setFieldType(field.type);
    setFieldToEdit(field);
    setIsModalOpen(true);
  };

  const handleModalSubmit = (field: IFormField) => {
    if (fieldToEdit) {
      setFieldCollections((prevCollections) => {
        const newCollections = [...prevCollections];
        const collectionIndex = newCollections.findIndex((collection) =>
          collection.fields.includes(fieldToEdit)
        );
        const fieldIndex =
          newCollections[collectionIndex].fields.indexOf(fieldToEdit);
        newCollections[collectionIndex].fields[fieldIndex] = field;
        return newCollections;
      });
    } else {
      const newCollection: IFieldCollection = {
        fields: [field],
      };
      setFieldCollections((prev) => [...prev, newCollection]);
    }
    setFieldToEdit(null);
  };

  const addedCollections = fieldCollections.map(({ name }) => name);
  const availableCollections = Object.values(PreDefinedCollection).filter(
    (collectionEnum) => !addedCollections.includes(collectionEnum)
  );

  const getTimezone = useMemo(() => {
    const minimalTimeOne = minimalTimezoneSet.filter(
      (d) => d?.offset === timeZone
    );
    if (!minimalTimeOne.length) return '';

    const { offset, tzCode, label } = minimalTimeOne[0];
    const tzAbbrMoment = moment.tz(tzCode).format('z');
    const isAbbrValid = !tzAbbrMoment.match(/[+-]/);

    return isAbbrValid ? `(GMT${offset}) ${tzAbbrMoment}` : label;
  }, [timeZone]);

  const updateTimezone = (offset: string) => {
    const updatedStartMoment = moment(registrationStart).utcOffset(
      mapTimezoneOffsetToMinutes(offset)
    );
    const updatedEndMoment = moment(registrationEnd).utcOffset(
      mapTimezoneOffsetToMinutes(offset)
    );

    setRegistrationStart(updatedStartMoment);
    setRegistrationEnd(updatedEndMoment);
    form.change('startDate', updatedStartMoment.valueOf());
    form.change('endDate', updatedEndMoment.valueOf());
    form.change('timeZone', offset);
  };

  const handlePreview = () => {
    const formData: RegistrationFormData = {
      description: form.getState().values.description,
      openingDate: registrationStart.valueOf(),
      closingDate: registrationEnd.valueOf(),
      timeZone: timeZone,
      fieldCollections: fieldCollections,
      type: 'registration',
    };
    setPreviewData(formData);
    setIsPreviewMode(true);
  };

  const handleSave = () => {
    console.log("SAVE_PREVIEW_DATA",previewData)
    if (previewData) {
      form.change('registrationForm', previewData);
      setOpen(false);
      setIsPreviewMode(false);
      setPreviewData(null);
    }
  };

  useEffect(() => {
    if (initialValues) {
      if (initialValues.startDate) {
        setRegistrationStart(moment(initialValues.startDate));
        form.change('startDate', initialValues.startDate);
      }
      if (initialValues.endDate) {
        setRegistrationEnd(moment(initialValues.endDate));
        form.change('endDate', initialValues.endDate);
      }
      if (initialValues.timeZone) {
        setTimeZone(initialValues.timeZone);
        form.change('timeZone', initialValues.timeZone);
      }
    }
  }, [initialValues, form]);
  return (
    <Drawer
      open={open}
      headerText={
        isPreviewMode ? t('event:summaryForm') : t('event:registrationForm')
      }
      onIconClick={() => setOpen(false)}
      onDrawerClose={() => setOpen(false)}
      submitText={
        isPreviewMode ? t('editUserProfile:save') : t('event:preview')
      }
      onSubmit={isPreviewMode ? handleSave : handlePreview}
    >
      {isPreviewMode && previewData ? (
        <RegistrationFormPreview
          description={previewData.description}
          startDate={moment(previewData.openingDate)}
          endDate={moment(previewData.closingDate)}
          timeZone={previewData.timeZone}
          fieldCollections={previewData.fieldCollections}
        />
      ) : (
        <>
          <SectionWrapper pt={1}>
            <InputDescriptionField
              label={t('event:descriptionOptional')}
              name="description"
              showCharactersNumber
              maxLength={3000}
              height={100}
              validate={composeValidators(
                required(t('error:thisFieldIsRequired'))
              )}
            />
          </SectionWrapper>

          <SectionWrapper pt={16.5} pb={16.5}>
            <SectionHeader>{t('event:registrationDates')}</SectionHeader>

            <Field name="startDate">
              {({ input }) => (
                <ScheduleTime
                  selectedDate={getDateInOriginalTimezone(registrationStart)}
                  headerText={t('timePicker:start')}
                  onScheduleTimeChange={(value) => {
                    const updatedMoment = moment(value).utcOffset(
                      mapTimezoneOffsetToMinutes(timeZone),
                      true
                    );
                    setRegistrationStart(updatedMoment);
                    input.onChange(updatedMoment.valueOf());
                  }}
                  minDate={new Date()}
                />
              )}
            </Field>

            <Field name="endDate">
              {({ input }) => (
                <ScheduleTime
                  selectedDate={getDateInOriginalTimezone(registrationEnd)}
                  headerText={t('timePicker:end')}
                  onScheduleTimeChange={(value) => {
                    const updatedMoment = moment(value).utcOffset(
                      mapTimezoneOffsetToMinutes(timeZone),
                      true
                    );
                    setRegistrationEnd(updatedMoment);
                    input.onChange(updatedMoment.valueOf());
                  }}
                  minDate={registrationStart.toDate() || new Date()}
                />
              )}
            </Field>

            <SeparateSettingsHeader mb={4} mt={16}>
              {t('timePicker:timeZone')}
            </SeparateSettingsHeader>

            <Field name="timeZone">
              {() => {
                form.change('timeZone', timeZone);
                return (
                  <SelectWrapper>
                    <SelectIconWrap>
                      <WorldIcon />
                    </SelectIconWrap>
                    <TimeZoneText>{getTimezone}</TimeZoneText>
                    <Select
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setTimeZone(e.target.value);
                        updateTimezone(e.target.value);
                      }}
                      value={timeZone}
                    >
                      {minimalTimezoneSet.map((tz, index) => (
                        <option value={tz.offset} key={index}>
                          {tz.label}
                        </option>
                      ))}
                    </Select>
                  </SelectWrapper>
                );
              }}
            </Field>
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader>{t('event:selectTemplates')}</SectionHeader>
            {availableCollections.map((collectionEnum) => (
              <Pill
                text={'+'+ t(`event:${collectionEnum}`)}
                onClick={() => handleAddCollection(collectionEnum)}
                fontSize={12}
                margin="0 10px 8px 0"
              />
            ))}
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader>{t('event:addQuestion')}</SectionHeader>
            {Object.values(FormFieldType).map((type) => (
              <Pill
                key={type}
                text={t(`event:${FormFieldTypeLabels[type]}`)}
                onClick={() => handleAddCustomField(type)}
                fontSize={12}
                margin="0 10px 8px 0"
              />
            ))}
          </SectionWrapper>

          <FieldDetailsModal
            open={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              setFieldToEdit(null);
            }}
            onSubmit={handleModalSubmit}
            fieldType={fieldType}
            initialValues={fieldToEdit}
          />

          <SectionWrapper>
            <Text>{t('event:questions')}</Text>
            {fieldCollections.map((collection, collectionIndex) => (
              <FieldCollection
                key={`${collection.name}-${collectionIndex}`}
                collection={collection}
                onRemoveField={(fieldIndex) =>
                  handleRemoveField(collectionIndex, fieldIndex)
                }
                onEditField={(fieldIndex) =>
                  handleEditField(collectionIndex, fieldIndex)
                }
              />
            ))}
          </SectionWrapper>
        </>
      )}
    </Drawer>
  );
};

export default RegistrationFormDrawer;
