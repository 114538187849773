import React from 'react';
import { FormField, FormFieldType, IFormField } from '../FormField/FormField';
import { SectionHeader } from 'forms/CreateEventPostForm/CreateEventPostForm.styles';
import Text from 'components/common/Text';
import Dropdown from 'components/Dropdown';
import { ThreeDotsIcon } from 'public/assets/icons';
import {
  DropdownWrapper,
  ThreeDotsWrap,
  FieldWrapper,
  FieldContent,
} from './FieldCollection.styles';
import { useTranslation } from 'react-i18next';

export interface IFieldCollection {
  name?: string;
  fields: IFormField[];
}

export interface FieldCollectionProps {
  collection: IFieldCollection;
  readOnly?: boolean;
  onRemoveField?: (fieldIndex: number) => void;
  onEditField?: (fieldIndex: number) => void;
}

export const FieldCollection = ({
  collection,
  readOnly,
  onRemoveField,
  onEditField,
}: FieldCollectionProps) => {
  const {t}=useTranslation()
  return (
    <div style={{ marginTop: 10 }}>
      {collection.name && <SectionHeader>{t(`event:${collection.name}`)}</SectionHeader>}
      {collection.fields.map((field, i) => {
        return (
          <FieldWrapper key={i}>
            <FieldContent>
              {readOnly ? (
                <>
                  {field.type === FormFieldType.TextBox && (
                    <Text>{field?.label}</Text>
                  )}
                  {field.type === FormFieldType.Checkbox && (
                    <Text>{field?.label}</Text>
                  )}
                  {field.type === FormFieldType.MultipleOptions && (
                    <Text>
                      {field?.options?.map((option) => option.label).join(', ')}
                    </Text>
                  )}
                  {field.type === FormFieldType.Paragraph && (
                    <Text>{field?.label}</Text>
                  )}
                </>
              ) : (
                <FormField key={i} field={field} />
              )}
            </FieldContent>
            {!readOnly && (
              <Dropdown
              top={true}
              right={true}
                listItems={[
                  {
                    id: 0,
                    text: t('event:editQuestion'),
                    onClick: () => onEditField(i),
                  },
                  {
                    id: 1,
                    text: t('event:deleteQuestion'),
                    onClick: () => onRemoveField(i),
                  },
                ]}
              >
                <DropdownWrapper>
                  <ThreeDotsWrap>
                    <ThreeDotsIcon colorStroke="#C4C4C4" />
                  </ThreeDotsWrap>
                </DropdownWrapper>
              </Dropdown>
            )}
          </FieldWrapper>
        );
      })}
    </div>
  );
};
