import {
  InvolvedUserTypes,
  FollowingStatus,
  WaitlistingStatus,
} from 'utils/constants';
import i18n from 'utils/i18n';

export const getHeaderTitle = (involvedUserType) => {
  switch (involvedUserType) {
    case InvolvedUserTypes.MY_FOLLOWERS:
    case InvolvedUserTypes.USER_FOLLOWERS:
    case InvolvedUserTypes.GROUP_FOLLOWERS:
      return i18n.t('search:followers');

    case InvolvedUserTypes.MY_FOLLOWING:
    case InvolvedUserTypes.USER_FOLLOWING:
      return i18n.t('profile:following');

    case InvolvedUserTypes.GROUP_MEMBERS:
      return i18n.t('search:members');

    case InvolvedUserTypes.GROUP_ADMINS:
      return i18n.t('addNewGroup:admins');

    case InvolvedUserTypes.EVENT_ATTENDING:
      return i18n.t('event:Attending');

    case InvolvedUserTypes.EVENT_WAITLISTING:
      return i18n.t('event:Waitlisting');

    case InvolvedUserTypes.EVENT_INVITED:
      return i18n.t('event:Invited');

    default:
      return '';
  }
};

export const getInvolvedUsersVariable = (involvedUserType) => {
  switch (involvedUserType) {
    case InvolvedUserTypes.MY_FOLLOWERS:
    case InvolvedUserTypes.USER_FOLLOWERS:
      return 'userFollowers';

    case InvolvedUserTypes.MY_FOLLOWING:
    case InvolvedUserTypes.USER_FOLLOWING:
      return 'userFollowing';

    case InvolvedUserTypes.GROUP_FOLLOWERS:
      return 'groupFollowers';

    case InvolvedUserTypes.GROUP_MEMBERS:
      return 'groupMembers';

    case InvolvedUserTypes.GROUP_ADMINS:
      return 'groupAdmins';

    case InvolvedUserTypes.EVENT_ATTENDING:
      return 'eventAttending';

    case InvolvedUserTypes.EVENT_WAITLISTING:
      return 'eventWaitListing';

    case InvolvedUserTypes.EVENT_INVITED:
      return 'eventInvited';

    default:
      return '';
  }
};

export const isSearchVisible = (involvedUserType) =>
  [
    involvedUserType === InvolvedUserTypes.GROUP_FOLLOWERS,
    involvedUserType === InvolvedUserTypes.GROUP_MEMBERS,
    involvedUserType === InvolvedUserTypes.MY_FOLLOWERS,
    involvedUserType === InvolvedUserTypes.MY_FOLLOWING,
    involvedUserType === InvolvedUserTypes.USER_FOLLOWERS,
    involvedUserType === InvolvedUserTypes.USER_FOLLOWING,
  ].some((cond) => cond);

export const getButtonText = (status) => {
  switch (status) {
    case FollowingStatus.NOT_FOLLOWING:
      return i18n.t('group:followUppercase');

    case FollowingStatus.REQUESTED:
      return i18n.t('group:requestedUppercase');

    case FollowingStatus.FOLLOWING:
      return i18n.t('group:followingUppercase');

    case WaitlistingStatus.NOTIFY:
      return i18n.t('event:notifyUppercase');

    case WaitlistingStatus.NOTIFIED:
        return i18n.t('event:notifiedUppercase');

    default:
      return '';
  }
};

export const isVerticalDotsVisible = ({
  involvedUserType,
  isAdmin = false,
}) => {
  if (involvedUserType === InvolvedUserTypes.MY_FOLLOWERS) {
    return true;
  }
  if (involvedUserType === InvolvedUserTypes.GROUP_FOLLOWERS && isAdmin) {
    return true;
  }
  if (involvedUserType === InvolvedUserTypes.GROUP_MEMBERS && isAdmin) {
    return true;
  }

  return false;
};

export const isPaginationUsed = (involvedUserType) =>
  ![
    InvolvedUserTypes.GROUP_ADMINS,
    InvolvedUserTypes.EVENT_INVITED,
    InvolvedUserTypes.EVENT_ATTENDING,
    InvolvedUserTypes.EVENT_WAITLISTING,
  ].some((el) => el === involvedUserType);

export const getPopoverOption = (involvedUserType) => {
  switch (involvedUserType) {
    case InvolvedUserTypes.MY_FOLLOWERS:
      return i18n.t('profile:removeUserFromMyFollowers');

    case InvolvedUserTypes.GROUP_FOLLOWERS:
      return i18n.t('profile:removeUserFromMyGroupsFollowers');

    case InvolvedUserTypes.GROUP_MEMBERS:
      return i18n.t('profile:removeUserFromMyGroupMembers');

    default:
      return '';
  }
};

export const getRemoveModalBodyText = (involvedUserType) => {
  switch (involvedUserType) {
    case InvolvedUserTypes.MY_FOLLOWERS:
      return i18n.t('confirmation:removeUserFromMyFollowersConfirmation');

    case InvolvedUserTypes.GROUP_FOLLOWERS:
      return i18n.t('confirmation:removeUserFromMyGroupFollowersConfirmation');

    case InvolvedUserTypes.GROUP_MEMBERS:
      return i18n.t('confirmation:removeUserFromMyGroupMembersConfirmation');

    default:
      return '';
  }
};
