import { IFieldCollection } from 'components/DynamicFormComponents/RegistrationForm/FieldCollection/FieldCollection';
import { FormFieldType } from 'components/DynamicFormComponents/RegistrationForm/FormField/FormField';

export enum PreDefinedCollection {
  PersonalInfo = 'personalInfo',
  VehicleInfo = 'vehicleInfo',
  PersonalID = 'personalID',
  ClubMemberID = 'clubMemberID',
  FoodOption = 'foodOption',
  GuestsInfo = 'guestsInfo',
}

export const personalInfoCollection: IFieldCollection = {
  name: PreDefinedCollection.PersonalInfo,
  fields: [
    {
      name: 'firstName',
      type: FormFieldType.TextBox,
      label: 'First Name',
      required: true,
      maxLength: 50,
    },
    {
      name: 'lastName',
      type: FormFieldType.TextBox,
      label: 'Last Name',
      required: true,
      maxLength: 50,
    },
    {
      name: 'birthDate',
      type: FormFieldType.DatePicker,
      label: 'Birth Date',
      required: true,
    },
  ],
};

export const vehicleInfoCollection: IFieldCollection = {
  name: PreDefinedCollection.VehicleInfo,
  fields: [
    {
      name: 'plate',
      type: FormFieldType.TextBox,
      label: 'Plate',
      required: true,
      maxLength: 10,
    },
    {
      name: 'make',
      type: FormFieldType.TextBox,
      label: 'Make',
      required: true,
      maxLength: 50,
    },
    {
      name: 'model',
      type: FormFieldType.TextBox,
      label: 'Model',
      required: true,
      maxLength: 50,
    },
    {
      name: 'year',
      type: FormFieldType.TextBox,
      label: 'Year',
      required: true,
    },
  ],
};

export const personalIDCollection: IFieldCollection = {
  name: PreDefinedCollection.PersonalID,
  fields: [
    {
      name: 'personalID',
      type: FormFieldType.TextBox,
      label: 'Personal ID',
      required: true,
      maxLength: 20,
    },
  ],
};

export const clubMemberIDCollection: IFieldCollection = {
  name: PreDefinedCollection.ClubMemberID,
  fields: [
    {
      name: 'clubMemberID',
      type: FormFieldType.TextBox,
      label: 'Club Member ID',
      required: true,
      maxLength: 20,
    },
  ],
};

export const foodOptionCollection: IFieldCollection = {
  name: PreDefinedCollection.FoodOption,
  fields: [
    {
      name: 'foodOption',
      type: FormFieldType.MultipleOptions,
      label: 'Food Option',
      required: true,
      options: [
        { label: 'Vegetarian', value: 'vegetarian' },
        { label: 'Vegan', value: 'vegan' },
        { label: 'Gluten-Free', value: 'gluten_free' },
        { label: 'Halal', value: 'halal' },
        { label: 'Kosher', value: 'kosher' },
      ],
    },
  ],
};

export const guestsInfoCollection: IFieldCollection = {
  name: PreDefinedCollection.GuestsInfo,
  fields: [
    {
      name: 'totalGuests',
      type: FormFieldType.TextBox,
      label: 'Total Guests',
      required: true,
    },
    {
      name: 'isAttending',
      type: FormFieldType.Checkbox,
      label: 'Is Attending',
      required: true,
    },
  ],
};

export const getCollectionByEnum = (
  collectionEnum: PreDefinedCollection
): IFieldCollection | undefined => {
  switch (collectionEnum) {
    case PreDefinedCollection.PersonalInfo:
      return personalInfoCollection;

    case PreDefinedCollection.VehicleInfo:
      return vehicleInfoCollection;

    case PreDefinedCollection.PersonalID:
      return personalIDCollection;

    case PreDefinedCollection.ClubMemberID:
      return clubMemberIDCollection;

    case PreDefinedCollection.FoodOption:
      return foodOptionCollection;

    case PreDefinedCollection.GuestsInfo:
      return guestsInfoCollection;

    default:
      return undefined;
  }
};
