import { InputField } from 'components';
import React, { useState } from 'react';
import { Select } from 'antd';
import CheckBox from 'components/common/CheckBox';
import { Field } from 'react-final-form';
import { LabelCheckboxWrap, LabelText } from 'components/Modals/FieldDetailsModal/FieldDetailsModal.style';

export enum FormFieldType {
  TextBox = 'text',
  MultipleOptions = 'multiple',
  Paragraph = 'paragraph',
  Checkbox = 'checkbox',
  DatePicker = 'date',
}

export const FormFieldTypeLabels: Record<FormFieldType, string> = {
  [FormFieldType.TextBox]: 'textBox',
  [FormFieldType.MultipleOptions]: 'multipleOptions',
  [FormFieldType.Paragraph]: 'paragraph',
  [FormFieldType.Checkbox]: 'checkbox',
  [FormFieldType.DatePicker]: 'datePicker',
};

export interface IFormField {
  name: string;
  type: FormFieldType;
  label: string;
  required: boolean;
  maxLength?: number;
  value?: string;
  onChange?: (value: string) => void;
  options?: {
    label: string;
    value: string;
  }[];
}

export interface FormFieldProps {
  field: IFormField;
}

export const FormField = ({ field }: FormFieldProps) => {
  const [value, setValue] = useState(field.value || '');

  const handleChange = (newValue: string) => {
    setValue(newValue);
    if (field.onChange) {
      field.onChange(newValue);
    }
  };

  return (
    <div style={{ marginTop: 10 }}>
      {field.type === FormFieldType.TextBox && (
        <InputField
          label={field.label}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          type="text"
          maxLength={field.maxLength || 50}
          showCharactersNumber
          placeholder={field.label}
        />
      )}

      {field.type === FormFieldType.MultipleOptions && (
        <Select
          mode="multiple"
          placeholder="Select options"
          onChange={handleChange}
          options={field.options?.map((option) => ({
            value: option.value,
            label: option.label,
          }))}
          bordered={false}
          style={{
            width: '100%',
            height: 44,
            outline: 'none',
            fontFamily: 'Poppins',
            fontWeight: 400,
            borderRadius: 4,
            background: '#f7f7f7',
            border: '1px solid #ff950a',
          }}
        />
      )}

      {field.type === FormFieldType.Paragraph && (
        <InputField
          label={field.label}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          type="textarea"
          maxLength={field.maxLength || 500}
          showCharactersNumber
          placeholder={field.label}
        />
      )}

      {field.type === FormFieldType.Checkbox && (
        <>
          <Field name="required" type="checkbox">
            {(props) => (
              <LabelCheckboxWrap>
                <CheckBox
                  {...props.input}
                  type="checkbox"
                  checked={props.input.checked}
                  height={8}
                  width={8}
                />
                <LabelText>{field.label}</LabelText>
              </LabelCheckboxWrap>
            )}
          </Field>
        </>
      )}

      {field.type === FormFieldType.DatePicker && (
        <InputField
          label={field.label}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          type="date"
          maxLength={field.maxLength || 50}
          showCharactersNumber
          placeholder={field.label}
        />
      )}
    </div>
  );
};
